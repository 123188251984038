/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react';
import { Layout } from '@components/Layout';
import { FormattedMessage as M } from 'gatsby-plugin-intl';
import { useStores } from '@stores';

interface ILogoutPage {
}

const LogoutPage: FC<ILogoutPage> = () => {
  const { authStore } = useStores();
  useEffect(() => {
    authStore.logout();
  });

  return (
    <Layout>
      <M id="header.logout" />
    </Layout>
  );
};

export default LogoutPage;
